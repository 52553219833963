<template>
  <span>
    <label class="switch" :class="size" :for="name">
      <input
        type="checkbox"
        :name="name"
        :id="name"
        :checked="value"
        :disabled="disabled"
        @change="changeChecked"
      />
      <span class="slider" :class="size" />
    </label>
  </span>
</template>

<script>
export default {
  model: {
    event: 'change',
  },
  props: {
    name: {
      type: [String, Number],
      required: true,
    },
    size: {
      type: String,
      default: 'sm',
    },
    value: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    confirmation: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    changeChecked($event) {
      const { target } = $event;
      this.$emit('change', target.checked);
      if (this.confirmation) {
        target.checked = !target.checked;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/shared/toggle-switch.scss";
</style>
